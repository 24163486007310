<template>
    <div>
        <div v-if="!patientSubmitted">
            <multiselect 
            placeholder=""
            label="full_name"
            v-model="patient"
            track-by="patient_id"
            :showNoOptions="true"
            :options="patientOptions"
            :loading="patientLoading"
            :internal-search="false"
            :show-labels="false"
            :clearOnSelect="true"
            @search-change="debouncePatients"
            @select="handlePatientAddress"
            @remove="clearPatient"
            @open="clearPatient">
            <template slot="option" slot-scope="props">
                <div style="display:flex;flex-flow:row wrap;">
                    <div style="text-align:left;width:33.33%;">
                        {{props.option.full_name}}
                    </div>
                    <div style="text-align:center;width:33.33%;">
                        <span class="option-block">Acct#</span> {{props.option.account_no}} 
                    </div>
                    <div style="text-align:right;width:33.33%;">
                        <span class="option-block">DOB</span>
                        {{formatDOB(props.option.date_of_birth)}}
                    </div>
                </div>
            </template>
            <template slot="placeholder">
                Start by typing patient name or account #
            </template>
            <template slot="singleLabel" slot-scope="props">
                <b-button class="single-label">
                    <div style="padding:4px;padding-left:10px;padding-right:20px">{{props.option.full_name}}</div>
                    <div><b-icon style="color:#9CA3AD" icon="x" font-scale="2rem"></b-icon></div>
                </b-button>
            </template>
            <template slot="caret">
                <b-icon class="multiselect-icon" icon="search" font-scale="1.5"></b-icon>
            </template>
            <template slot="noResult">
                <div >
                    No patients found
                </div>
            </template>
            <template slot="noOptions">
                Type at least two characters to search
            </template>
            </multiselect>
        </div>
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import { make_url, authenticated_request, debounce } from '../../common.js'

export default {
    name: 'PatientSelection',
    components: { 
        Multiselect
    },
    props: ['optionsLimit', 'value',],
    data() {
        return {
            patient: null,
            address: null,
            searchText: "",
            limit: 20,
            selectablePatients: [],
            patientLoading: false
        }
    },
    created() {
        this.debouncePatients = debounce(this.searchChange, 300);
        if (this.optionsLimit){
            this.limit = this.optionsLimit;
        }
    },
    methods: {
        searchChange: function(searchQuery) {
            this.patientLoading = true;
            this.searchText = searchQuery;
            if (searchQuery.length > 0) {
                this.getPatients();
            }
            else {
                // this.selectablePatients = [];
                this.patientLoading = false;
            }
            
        },
        getPatients: function() {
            var params = {
                search: this.searchText,
                limit: this.limit
            }
            
            const url = make_url("/reports/patients/autocomplete");
            const vm = this;
            const promise = authenticated_request({
                method: "get",
                url: url,
                params: params
            }).then(function(response){
                const patients = response.data.patients;
                vm.selectablePatients = patients || [];
                vm.patientLoading = false;
                return patients || [];
            }).catch(function(errors) {
                vm.$store.commit("preauths/hideAlerts");
                errors.forEach(function (error) {
                    vm.$store.commit("preauths/displayAlerts", error.message);
                });
            });
            
            return promise;
        },
        formatDOB: function(orig_dob) {
            var dob = ""
            if (orig_dob) {
                const date_split = orig_dob.split("-");
                dob = date_split[1] + "/" + date_split[2] + "/" + date_split[0];
            }
            return dob;
        },
        clearPatient: function() {
            this.$store.commit("preauths/setDefaultPatient");
            this.patient = null;
            this.address = null;
        },
        handlePatientAddress: function(patient) {
            var patient_id = patient.patient_id;
            if (patient.address) {
                return this.getPatientAddress(patient_id);
            }
            return this.addPatientAddress(patient_id);
        },
        addPatientAddress: function(patient_id) {
            var params = {}
            const url = make_url("/patients/" + patient_id + "/address");
            const vm = this;
            const promise = authenticated_request({
                method: "post",
                url: url,
                data: params
            }).then(function(response){
                if (response) {
                    return vm.getPatientAddress(patient_id);
                }
            }).catch(function(error) {
                this.handleErrors(error);
            });
            
            return promise;
        },
        getPatientAddress: function(patient_id) {
            const url = make_url("/patients/" + patient_id + "/address");
            const vm = this;
            const promise = authenticated_request({
                method: "get",
                url: url
            }).then(function(response){
                const address = response.data;
                var telephone = address.telephone || "";
                var email = address.email || "";
                vm.address =
                    {"telephone": telephone,
                    "email": email};
                
                return address;
            }).catch(function(error) {
                this.handleErrors(error);
            });
            
            return promise;
        },
        handleErrors(error) {
            this.$store.commit("preauths/hideAlerts");
            var messages = [];
            if (error.response.status == 400) {
                messages.push(error.response.data.msg);
            }
            if (error.response.status == 404) {
                if (error.response.data) {
                    console.log(error.response.data.msg);
                    messages.push(error.response.data.msg);
                } else {
                    console.log("Resource not found");
                    messages.push("The requested resource or page could not be located.");
                }
            }
            this.$store.commit("preauths/displayAlerts", messages);
        },
        setPatientState: function() {
            this.$store.state.preauths.patient.patient = this.patient
        },
        setAddressState: function() {
            this.$store.state.preauths.patient.address = this.address
        }
    },
    computed: {
        patientOptions() {
            return this.selectablePatients;
        },
        patientState() {
            return this.$store.state.preauths.patient.patient;
        },
        patientSubmitted() {
            return this.$store.state.preauths.patient.submitted;
        },
        tryPatientSubmitted() {
            return this.$store.state.preauths.patient.trySubmit;
        },
        selected() {
            return this.$store.state.preauths.patient.selected;
        }
    },
    watch:
    {
        patient: function() {
            if (this.patient) {
                this.setPatientState();
                this.$store.commit("preauths/patientSelected");
                if (this.showAlerts) {
                    this.$store.commit("preauths/hideAlerts");
                }
                this.$emit('input', this.patient);
            }
        },
        address: function() {
            if (this.address) {
                this.setAddressState();
            }
        },
        patientState: function() {
            if (!this.patientState && this.patient){
                this.patient = null;
                this.address = null;
            }
        },
        selected: function() {
            if (!this.selected) {
                this.patient = null;
                this.address = null;
            }
        },
        tryPatientSubmitted: function(){
            if (this.tryPatientSubmitted) {
                this.$store.commit("preauths/hideAlerts");
                if (!this.patientSubmitted && !this.selected) {
                    this.$store.commit("preauths/displayAlerts", ["Please select or create a patient to continue."])
                }
            }
        }
    }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped>
.multiselect-icon {
    color: #9CA3AD;
    position: absolute;
    right: 0;
    margin-top:9.5px;
    margin-right:15px;
}
::v-deep .multiselect__spinner {
    background: #DDE4F5;
}
.single-label {
    background: #ccd6f1;
    font-size: 12px/14px;
    font-family: "Roboto", sans-serif;
    color:var(--dark);
    border-radius:5px;
    border: 0px;
    max-height: 30px;
    margin:0px;
    padding:0px;
    display:flex;
}
.option-block {
    border: 2px solid #E0EAF8;
    background: #F2F6FC;
    padding: 3px;
}
</style>