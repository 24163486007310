





























































































import Vue from "vue";
import PatientSelection from "../patients/PatientSelection.vue";
import InsuranceList from "../payers/InsuranceList.vue";
import CptCodesList from "../codes/CptCodesList.vue";
import PracticeMultiselect from "../multiselects/PracticeMultiselect.vue";

export default Vue.extend({
  name: "PatientEstimate",
  data() {
    return {
      patient: null,
      practice: null,
    };
  },
  components: {
    PatientSelection,
    PracticeMultiselect,
    InsuranceList,
    CptCodesList,
  },
  methods: {
    setInsurances(insurances: any) {
      this.$store.commit("preauths/setInsurances", insurances);
    },
    renderCurrentDate() {
      return new Date().toLocaleDateString("en-US");
    },
    printEstimate() {
      window.print();
    },
  },
  computed: {
    primaryInsurance(): any {
      return this.$store.getters["preauths/insuranceModulePrimaryInsurance"];
    },
    payer_id(): number | null {
      return this.primaryInsurance?.payer_id;
    },
  },
});
